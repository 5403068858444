// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-21st-birthday-card-js": () => import("./../../../src/pages/21st-birthday-card.js" /* webpackChunkName: "component---src-pages-21st-birthday-card-js" */),
  "component---src-pages-30th-birthday-card-js": () => import("./../../../src/pages/30th-birthday-card.js" /* webpackChunkName: "component---src-pages-30th-birthday-card-js" */),
  "component---src-pages-40th-birthday-card-js": () => import("./../../../src/pages/40th-birthday-card.js" /* webpackChunkName: "component---src-pages-40th-birthday-card-js" */),
  "component---src-pages-50th-birthday-card-js": () => import("./../../../src/pages/50th-birthday-card.js" /* webpackChunkName: "component---src-pages-50th-birthday-card-js" */),
  "component---src-pages-60th-birthday-card-js": () => import("./../../../src/pages/60th-birthday-card.js" /* webpackChunkName: "component---src-pages-60th-birthday-card-js" */),
  "component---src-pages-70th-birthday-card-js": () => import("./../../../src/pages/70th-birthday-card.js" /* webpackChunkName: "component---src-pages-70th-birthday-card-js" */),
  "component---src-pages-80th-birthday-card-js": () => import("./../../../src/pages/80th-birthday-card.js" /* webpackChunkName: "component---src-pages-80th-birthday-card-js" */),
  "component---src-pages-anniversary-card-js": () => import("./../../../src/pages/anniversary-card.js" /* webpackChunkName: "component---src-pages-anniversary-card-js" */),
  "component---src-pages-baby-shower-card-js": () => import("./../../../src/pages/baby-shower-card.js" /* webpackChunkName: "component---src-pages-baby-shower-card-js" */),
  "component---src-pages-birthday-card-for-friends-js": () => import("./../../../src/pages/birthday-card-for-friends.js" /* webpackChunkName: "component---src-pages-birthday-card-for-friends-js" */),
  "component---src-pages-birthday-card-js": () => import("./../../../src/pages/birthday-card.js" /* webpackChunkName: "component---src-pages-birthday-card-js" */),
  "component---src-pages-birthday-video-maker-js": () => import("./../../../src/pages/birthday-video-maker.js" /* webpackChunkName: "component---src-pages-birthday-video-maker-js" */),
  "component---src-pages-boss-day-card-js": () => import("./../../../src/pages/boss-day-card.js" /* webpackChunkName: "component---src-pages-boss-day-card-js" */),
  "component---src-pages-christmas-video-card-js": () => import("./../../../src/pages/christmas-video-card.js" /* webpackChunkName: "component---src-pages-christmas-video-card-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-farewell-cards-js": () => import("./../../../src/pages/farewell-cards.js" /* webpackChunkName: "component---src-pages-farewell-cards-js" */),
  "component---src-pages-fathers-day-card-js": () => import("./../../../src/pages/fathers-day-card.js" /* webpackChunkName: "component---src-pages-fathers-day-card-js" */),
  "component---src-pages-get-well-soon-card-js": () => import("./../../../src/pages/get-well-soon-card.js" /* webpackChunkName: "component---src-pages-get-well-soon-card-js" */),
  "component---src-pages-goodbye-cards-js": () => import("./../../../src/pages/goodbye-cards.js" /* webpackChunkName: "component---src-pages-goodbye-cards-js" */),
  "component---src-pages-graduation-video-card-js": () => import("./../../../src/pages/graduation-video-card.js" /* webpackChunkName: "component---src-pages-graduation-video-card-js" */),
  "component---src-pages-how-to-1-js": () => import("./../../../src/pages/how-to-1.js" /* webpackChunkName: "component---src-pages-how-to-1-js" */),
  "component---src-pages-how-to-2-js": () => import("./../../../src/pages/how-to-2.js" /* webpackChunkName: "component---src-pages-how-to-2-js" */),
  "component---src-pages-how-to-3-js": () => import("./../../../src/pages/how-to-3.js" /* webpackChunkName: "component---src-pages-how-to-3-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lockdown-birthday-card-js": () => import("./../../../src/pages/lockdown-birthday-card.js" /* webpackChunkName: "component---src-pages-lockdown-birthday-card-js" */),
  "component---src-pages-memorial-group-video-card-js": () => import("./../../../src/pages/memorial-group-video-card.js" /* webpackChunkName: "component---src-pages-memorial-group-video-card-js" */),
  "component---src-pages-mothers-day-card-js": () => import("./../../../src/pages/mothers-day-card.js" /* webpackChunkName: "component---src-pages-mothers-day-card-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-thank-you-card-for-teacher-js": () => import("./../../../src/pages/thank-you-card-for-teacher.js" /* webpackChunkName: "component---src-pages-thank-you-card-for-teacher-js" */),
  "component---src-pages-wedding-greeting-card-js": () => import("./../../../src/pages/wedding-greeting-card.js" /* webpackChunkName: "component---src-pages-wedding-greeting-card-js" */)
}

